<template>
  <div class="commodityManagement-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>

    <el-dialog title="选择文件" :visible.sync="dialogVisibleUpload" @close="closeFun()" :close-on-click-modal="false">
      <div class="up_add_bg" @click='onImgFunBtn'>
        选择文件
        <input type="file" class="upload-btn" @change="addFile" ref="inputer" id="fileupload" multiple='false'
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
      </div>
      <div class="upload_fileName">文件名称：{{fileName}}</div>
      <!-- .xls .xslx 格式 -->
      <div class="dialog_bottom">
        <el-button type="primary" :disabled="sureBtnFlag" @click="sureBtn">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  let validCode = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~16位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("商品编号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validItemsName = (rule, value, callback) => {
      if (value == "" || value == undefined) {
          callback();
      } else {
          //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
          let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
          if (!reg.test(value)) {
              callback(new Error("商品名称格式不对或超过32个字符"));
          } else {
              callback();
          }
      }
  };
  import cabinetUtil from "@utils/cabinetUtil.js"
  import axios from "axios";
  export default {
    data() {
      let _this = this;
      return {
        fileName: '',
        formData: new FormData(),
        file: {}, //文件数据  
        dialogVisibleUpload: false,
        sureBtnFlag: false,
        nowIsSelfSale: 2,
        dialogVisible: false,
        opt: {
          title: "商品管理",
          search: [{
                key: "merchantId",
                label: "商家名称",
                type: 'remoteSearchById',
                opt: {
                    list: [],
                    change(data,cb) {
                      let findMerchant = _this.opt.search.find(item => item.key == 'merchantType');
                      let type = ''
                      _this.merchantList.forEach(item => {
                        if(item.value == data) {
                          type = item.merchantType
                        }
                      })
                      if(findMerchant) {
                          cb({
                              value: type,
                              key: findMerchant.key
                          });
                      }
                  }
                }
            }, 
            {
              key: "name",
              label: "商品名称",
              maxlength: 64,
              rules: [{
                  validator: validItemsName,
                  trigger: ['blur']
              }]
            },
            {
              key: "code",
              label: "商品编号",
              rules: [{
                validator: validCode,
                trigger: ['blur']
              }]
            },
            {
              key: "typeName",
              label: "商品品类",
            },
            {
              key: "auditState",
              label: "商品状态",
              type: "select",
              opt: {
                list: [{
                  label: "待审核",
                  value : "0",
                },{
                  label: "审核通过",
                  value : "1",
                },{
                  label: "审核不通过",
                  value : "2",
                }]
              }
            },
            {
              key: "supplierId",
              label: "供应商名称",
              type: 'remoteSearchById',
              opt: {
                list: []
              }
            },
            {
              label: "运营类型",
              key: "merchantType",
              type: "selectMust",
              value: 2,
              opt: {
                list: [{
                  label: "商家自营",
                  value : 1,
                },{
                  label: "商家运营",
                  value : 2,
                },{
                  label: "商家经销",
                  value : 3,
                }]
              }
            },
          ],
          columns: [{
              label: "商品名称",
              key: "name"
            },
            {
              label: "商品编号",
              key: "code"
            },
            {
              label: "商品品类",
              key: "typeName",
            },
            {
              label: "成本价",
              key: "costPriceVal"
            },
            {
              label: "经销价",
              key: "supplyPriceVal"
            },
            {
              label: "销售价",
              key: "sellPriceVal"
            },
            {
              label: "经销商",
              key: "supplierName"
            },
            {
              label: "商家",
              key: "merchantName"
            },
            {
              label: "供应商",
              key: "costName"
            },
            {
              label: "运营类型",
              key: "merchantTypeStr"
            },
            {
              label: "商品图片",
              key: "pic",
              type: "img-shop"
            },
            {
              label: "商品状态",
              key: "auditState"
            },
            {
              label: "操作",
              key: "action",
              type: "action-back",
              opt: {
                list: [
                  {
                    label: "审核",
                    on(row) {
                      if(row.merchantType == 3){
                        _this.$router.push("/main/commodityManagement/dealerDetail/edit/" + row.id + "/1");
                      }else{
                        _this.$router.push("/main/commodityManagement/detail/edit/" + row.id + "/1");
                      }
                      
                    }
                  },
                  {
                    label: "详情",
                    on(row) {
                      if(row.merchantType == 3){
                        _this.$router.push("/main/commodityManagement/dealerDetail/show/" + row.id + "/0");
                      }else{
                        _this.$router.push("/main/commodityManagement/detail/show/" + row.id + "/0");
                      }
                    }
                  }
                ]
              }
            }
          ],
          buttons: [{
              type: 1,
              on(data) {
                _this.$router.push({
                  path: data.data.merchantType == 3 ? "/main/commodityManagement/dealerDetail/edit/" + data.id + "/2" : "/main/commodityManagement/detail/edit/" + data.id + "/2",
                  query: {
                    merchantId: data.data.merchantId,
                    auditState: data.data.auditState
                  }
                });
              }
            },
            {
              type: 2,
              on(data) {
                if (data.ids.length > 1) {
                  return _this.$message({
                    showClose: true,
                    message: "不支持多个删除,只支持单个删除!",
                    type: "warning"
                  });
                }
                const h = _this.$createElement;
                let dto = {
                  id: data.ids[0],
                  state: 0
                };
                _this
                  .$msgbox({
                    title: "删除提示",
                    message: h("p", null, [
                      h(
                        "span", {
                          style: "color: #333;font-size: 16px;margin-left:98px"
                        },
                        "确定要删除商品吗？"
                      )
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                  })
                  .then(action => {
                    _this
                      .post("mall-service/items/updateState", dto)
                      .then(function () {
                        _this.$message({
                          showClose: true,
                          message: "删除货商品成功！",
                          type: "success"
                        });
                        data.refresh();
                      });
                  });
              }
            },],
        },
        categoryList: [],
        merchantList: []
      };
    },
    created() {
      console.log("commodityManagement-list created!!");
    },
    activated() {
      console.log("commodityManagement-list activated!!");
      let merchantFlag = this.getLoginType('loginType');
      if(merchantFlag) {
        this.opt.columns.forEach((item,index) => {
          if(item.key == 'action') {
            this.opt.columns.splice(index,1);
          }
        })
      } else {
        this.opt.search.forEach((item) => {
          if (item.key == 'merchantId') {
            cabinetUtil.getMerchantList({roleType: 1}).then(res => {
              item.opt.list = res;
              this.merchantList = res
            })
          }
        })
      }
      this.myColums = [];
      for (let index in this.opt.columns) {
        this.myColums.push(this.opt.columns[index]["key"]);
      }
      if(this.nowIsSelfSale !== 3) {
        this.opt.columns.forEach((item,index) => {
          if (item.key == 'supplyPriceVal') {
            this.opt.columns.splice(index, 1)
          }
        });
        this.opt.columns.forEach((item,index) => {
          if (item.key == 'supplierName') {
            this.opt.columns.splice(index, 1)
          }
        });
      }else if (this.nowIsSelfSale == 3) {
        if(this.myColums.indexOf("supplyPriceVal") == -1) {
          this.opt.columns.forEach((item,index) => {
            if(item.key == 'costPriceVal') {
              this.opt.columns.splice(index + 1, 0,{
                  label: "经销价",
                  key: "supplyPriceVal"
              })
            }
          });
        }
        if(this.myColums.indexOf("supplierName") == -1) {
          this.opt.columns.forEach((item,index) => {
            if(item.key == 'merchantName') {
              this.opt.columns.splice(index + 1, 0, {
                label: "经销商",
                key: "supplierName"
              })
            }
          });
        }
      }
      this.getMerchantList()
    },
    deactivated() {
      this.nowIsSelfSale = 2;
    },
    mounted() {
    },
    methods: {
      onGet(opt) {
        this.myColums = [];
        this.nowIsSelfSale = opt.searchForm.merchantType;
        for (let index in this.opt.columns) {
          this.myColums.push(this.opt.columns[index]["key"]);
        }
        if(opt.searchForm.merchantType !== 3) {
          this.opt.columns.forEach((item,index) => {
            if (item.key == 'supplyPriceVal') {
              this.opt.columns.splice(index, 1)
            }
          });
          this.opt.columns.forEach((item,index) => {
            if (item.key == 'supplierName') {
              this.opt.columns.splice(index, 1)
            }
          });
        }else if (opt.searchForm.merchantType == 3) {
          if(this.myColums.indexOf("supplyPriceVal") == -1) {
            this.opt.columns.forEach((item,index) => {
              if(item.key == 'costPriceVal') {
                this.opt.columns.splice(index + 1, 0,{
                    label: "经销价",
                    key: "supplyPriceVal"
                })
              }
            });
          }
          if(this.myColums.indexOf("supplierName") == -1) {
            this.opt.columns.forEach((item,index) => {
              if(item.key == 'merchantName') {
                this.opt.columns.splice(index + 1, 0, {
                  label: "经销商",
                  key: "supplierName"
                })
              }
            });
          }
        }
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          merchantType: opt.searchForm.merchantType ? opt.searchForm.merchantType : 2,
          ...opt.searchForm
        };
        this.post("mall-service/items/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          res.data.list.forEach(item => {
            item.action = [false,true];
            if (item.auditState == 0) {
              item.action[0] = true;
            } else if (item.auditState == 1) {
              item.action[0] = false;
            } else if (item.auditState == 2) {
              item.action[0] = false;
            }
            item.auditState = ["待审核","审核通过","审核不通过"][item.auditState];
            item.merchantTypeStr = ["商家自营","商家运营","商家经销"][item.merchantType - 1]
            item.costName = item.merchantType == 3? item.costName : item.supplierName
            item.costPriceVal = item.costPrice == null ? '' : item.costPrice.toFixed(2);
            item.sellPriceVal = item.sellPrice == null ? '' : item.sellPrice.toFixed(2);
            item.supplyPriceVal = item.supplyPrice == null ? '' : item.supplyPrice.toFixed(2);
            if (item.name.length > 17) {
              item.name = item.name.substr(0, 17) + '...';
            }
          })
          opt.cb(res.data);
        });
      },
      getCategory() {
        return new Promise((resolve, reject) => {
          let dto = {
            pageNum: 1,
            pageSize: 9999
          };
          this.post("mall-service/category/page/query", dto, {
            isUseResponse: true
          }).then(res => {
            var array = res.data.list;
            array.forEach(item => {
              item.value = item.id;
              item.label = item.name;
            })
            // this.opt.search.forEach(item => {
            //   if (item.key == "type") {
            //     item.opt.list = array;
            //   }
            // })
            resolve(array);
          })
        })

      },
      // 获取供应商列表
      getMerchantList() {
        cabinetUtil.getMerchantList({roleType: 2}).then(res => {
          this.opt.search.forEach(item => {
            if(item.key == 'supplierId') {
              item.opt.list = res
            }
          })
        })
      },
      onImgFunBtn() {
        document.getElementById('fileupload').click();
      },
      //选择文件
      addFile: function (e) {
        var _this = this;
        let inputDOM = this.$refs.inputer;
        this.file = inputDOM.files[0];
        let len = this.file.length;
        let size = Math.floor(this.file.size / 1024);
        if (size > 50 * 1024 * 1024) {
          alert("请选择50M以内的文件！");
          return false;
        }
        // this.showMask();
        this.sureBtnFlag = true;
        this.formData.append("file", this.file);
        axios({
            method: "POST",
            url: _this.UPLOAD_URL + "/mall-service/items/importData",
            data: this.formData,
            headers: {
              accessToken: localStorage.getItem("token") || sessionStorage.getItem("token"),
              securityKey: localStorage.getItem("securityKey") || sessionStorage.getItem("securityKey"),
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            this.sureBtnFlag = false;
            this.formData = new FormData();
            this.fileName = document.getElementById('fileupload').files[0].name;
            // this.hideMask();
            if (response.data.code == 0) {
              this.$message({
                type: 'success',
                message: '导入成功!'
              })
            } else {
              this.$message({
                type: 'warning',
                message: response.data.msg
              })
            }
            e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
          })
          .catch((error) => {
            this.sureBtnFlag = false;
            this.formData = new FormData();
            // this.hideMask();
            e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
          });
      },
      sureBtn() {
        this.dialogVisibleUpload = false;
        this.$refs.myList.get();
      },
      closeFun() {
        this.dialogVisibleUpload = false;
        this.$refs.myList.get();
      }
    }
  };
</script>
<style lang="scss" scope>
  .el-dialog {
    margin-top: 28vh !important;
    width: 562px;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .dialog_bottom {
    margin-top: 23px;
    width: 100%;
    text-align: center;

    .el-button {
      width: 80px !important;
      height: 36px !important;
      padding-top: 10px;
    }
  }

  .up_add_bg {
    width: 95px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #FFFFFF;
    border: 1px solid #CFCFD7;
    border-radius: 6px;
    cursor: pointer;

    .upload-btn {
      opacity: 0;
    }
  }

  .upload_fileName {
    padding-top: 10px;
    padding-right: 5px;
  }
</style>